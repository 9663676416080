








































































































































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import PersonDetail from '@/components/dashboard/PersonDetail/index.vue'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import RadioDialog from '@/components/common/RadioDialog/index.vue'
import addPersonal from '@/components/common/AddPersonnel/index.vue'
import pagination from '@/components/common/pagination/index.vue'
import CopyText from '@/components/common/CopyText.vue'
import { exportStatistic } from '@/utils/export'
import pageTitle from '@/components/common/pageTitle.vue'
import { variables } from '@/libs/theme'
import SecurityText from '@/components/common/SecurityText/index.vue'
import { tableScrollTop } from '@/utils/dom'
import { is, record } from '@/utils/helpers'
import moment from 'moment'
import _ from 'lodash'

@Component({
  name: 'detailPage',
  components: {
    FeatureImg,
    PersonDetail,
    addPersonal,
    pagination,
    CopyText,
    pageTitle,
    RadioDialog,
    SecurityText
  }
})
export default class DetialPage extends Vue {
  @Ref('paginationNode') paginationNode: pagination
  private shadow9Color = variables.shadow9Color
  private query = {
    limit: 20,
    offset: 0
  }
  private sels: any[] = []
  private tripList: any = null
  private tripCount = 0
  private latestTrip: any = null
  private loading = false
  private curPerson: any = null
  private showDetailDialog = false
  private zoneId = ''
  private typeRelation = {
    vip: 'VIP',
    black: '黑名单',
    white: '白名单'
  }
  private noNetwork = false
  private noDataImg = require('@/assets/images/dashboard/noData.png')
  private noNetworkImg = ''
  private moreMsg = false
  private allpersonalTitle = 'allpersonal'
  private filterData: AnyObj = {
    dateRange: [moment().startOf('day'), moment().endOf('day')],
    gender: 'all'
  }
  private searchPar: any = null
  private areaIds: any = null

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get isRootAccount() {
    return this.root === this.$store.state.user.scope
  }

  async mounted() {
    this.getTrips(this.filterData, this.query.limit, 0)
  }
  activated() {
    if (this.interval) this.fetchUpdate()
    this.setInterval()
  }

  private showExportDialog() {
    ;(this.$refs.exportDialog as Vue).show()
  }
  private async exportResult(exportAll = true) {
    let params = this.searchPar

    if (!exportAll) {
      if (this.sels.length === 0) return
      params = {
        ids: this.sels.map(i => i.id),
        startTime: params.startTime,
        endTime: params.endTime
      }
    }
    if (this.searchPar !== null) {
      // 毫秒
      const diff = params.endTime - params.startTime
      // 最大时间范围为31天
      const MaxDiffTime = 31 * 60 * 60 * 24 * 1000
      if (diff > MaxDiffTime) {
        this.$message({ message: '时间范围不能超过31天，请重新选择时间', type: 'error' })
      } else {
        const res = await this.$api.base.dayAccessExport(this.areaIds, _.pickBy(params, is.ava))
        exportStatistic(res.data, 'csv', 'person_detail.csv')
      }
    }
  }

  private getAlreadyPeron(row: any) {
    this.curPerson = { ...row }
    this.showDetailDialog = true
  }
  private pageChange(data: AnyObj) {
    this.query.limit = data.pageSize
    this.query.offset = (data.pageNo - 1) * data.pageSize
    this.getTrips(this.filterData, this.query.limit, this.query.offset)
  }
  private async refresh() {
    this.paginationNode.pageNo = 0
    this.query.offset = 0
    await this.getTrips(this.filterData, this.query.limit, this.query.offset)
  }
  async getTrips(params?: any, pageSize = 20, offset = 0) {
    this.closeMessage()
    this.loading = true
    try {
      const { data } = await this.getTripsList(params, pageSize, offset)
      if (data.code == 0) {
        const count = data.count
        const res = data.data

        this.tripList = res ? res : []
        this.tripCount = count ? count : 0

        if (offset === 0 && res && res.length > 0) {
          this.latestTrip = res[0]
        }
        this.paginationNode.init({ total: this.tripCount })
        tableScrollTop()
      }
    } finally {
      this.loading = false
    }
  }
  private async getTripsList(data: any, pageSize = 20, offset = 0) {
    const val = this.filterData
    const [startTime, endTime] = val.dateRange
    const identity = val.identity

    const par: AnyObj = {
      startTime,
      endTime,
      limit: pageSize,
      offset,
      identity,
      minAge: val.minAge || null,
      maxAge: val.maxAge || null,
      gender: val.gender === 'all' ? null : val.gender,
      minTimes: val.minTimes ?? null,
      maxTimes: val.maxTimes ?? null
    }
    this.areaIds = val.areaId || this.root
    this.searchPar = par

    return this.$api.base.getTripsList(this.areaIds, record(par).filter(is.ava))
  }
  private handleFilter() {
    this.getTrips(this.filterData, this.query.limit, 0)
    this.paginationNode.pageNo = 0
  }

  public selectPerson(obj: AnyObj) {
    obj.id = obj.personId
    this.curPerson = { ...obj }
    const val = this.filterData
    this.screenCon = {
      date: val.dateRange,
      areaIds: val.areaId ? [val.areaId] : [this.root]
    }
    this.showDetailDialog = true
  }
  public handleRegister(obj: any) {
    ;(this.$refs.addPersonalNode as Vue).open('detailAdd', obj)
  }
  public async handleEdit(id: string) {
    const res = await this.$api.base.getPersonSingle(id)
    ;(this.$refs.addPersonalNode as Vue).open('edit', res.data.data)
  }
  private selsChange(val: any) {
    this.sels = val
  }

  async fetchUpdate() {
    const { data, hideMessage } = (await this.getTripsList(this.filterData, 1)) as any

    if (data && data.error) {
      const message = data.error ? data.error.message : ''

      if (!this.message || this.message.type !== 'error') {
        this.closeMessage()
        this.message = this.$message({
          type: 'error',
          customClass: 'is-underlying',
          message: `${message} 更新获取失败`,
          duration: 0,
          showClose: true
        })
      }
      if (hideMessage) hideMessage()
    } else if (data.data) {
      const compareLastAppearTime = (newTrips: AnyObj[], oldTrips: AnyObj[]) => {
        if (newTrips.length > oldTrips.length) return true
        if (newTrips[0] && oldTrips[0]) {
          return newTrips[0].lastAppearTime > oldTrips[0].lastAppearTime
        }

        return false
      }

      if (compareLastAppearTime(data.data, [this.latestTrip])) {
        this.closeMessage()
        this.clearInterval()

        this.message = this.$message({
          message: '当前筛选条件下有新数据，点击刷新按钮刷新页面。',
          customClass: 'is-underlying',
          duration: 0,
          showClose: true,
          clickButton: '刷新',
          onClick: async () => {
            await this.getTrips(this.filterData, this.query.limit, 0)
            this.paginationNode.pageNo = 0
          },
          onClose: () => {
            this.setInterval()
          },
          createElement: this.$createElement
        })
      }
    }
  }
  private setInterval() {
    this.interval = setInterval(this.fetchUpdate, 1000 * 60)
  }
  private clearInterval() {
    if (this.interval) clearInterval(this.interval)
  }
  closeMessage() {
    if (this.message) {
      this.message.close()
      this.message = null
    }
  }
  private deactivated() {
    this.closeMessage()
    this.clearInterval()
  }
}
